import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import consumer from "../channels/consumer"

// Connects to data-controller="web-capture"
export default class extends Controller {
  static targets = ["spinner", "status", "timeInQueueOuter", "timeInQueue", "linkStatus"]
  static values = {
    uuid: String,
    url: String,
    enqueuedAt: String,
  }

  get enqueuedAt() { return this.enqueuedAtValue ? new Date(this.enqueuedAtValue) : null }

  connect() {
    if(this.enqueuedAt) {
      this.setupLiveUpdates()
      this.failsafeCheckTimer = setInterval(_ => this.checkForUpdates(), 10000)
      this.queueTimer = setInterval(_ => {
        this.timeInQueueOuterTarget.style.display = ""
        this.updateQueueTimer()
      }, 1000)
    }
  }

  updateQueueTimer() {
    this.timeInQueueTarget.innerText = this.friendlySeconds(Date.now() - this.enqueuedAt)
  }

  friendlySeconds(ms) {
    let s = Math.floor(ms / 1000)
    let r = []
    if (s >= 60) {
      const m = Math.floor(s / 60)
      s -= m * 60
      r.push(`${m}m`)
    }
    r.push(`${s < 10 ? "0" : ""}${s}s`)
    return r.join(" ")
  }

  disconnect() {
    if(this.queueTimer) {
      clearInterval(this.queueTimer)
      delete this.queueTimer
    }
    if(this.failsafeCheckTimer) {
      clearInterval(this.failsafeCheckTimer)
      delete this.failsafeCheckTimer
    }
  }

  setupLiveUpdates() {
    const ref = this
    this.subscription = consumer.subscriptions.create({
      channel: "WebCaptureChannel",
      uuid: this.uuidValue,
    }, {
      connected(data) {
        // console.log("connected", data)
        ref.linkStatusTarget.classList.remove("text-danger")
        ref.linkStatusTarget.classList.remove("text-warning")
        ref.linkStatusTarget.classList.remove("text-muted")
        ref.linkStatusTarget.classList.add("text-success")
        ref.linkStatusTarget.title = "live updates enabled"
      },

      disconnected(data) {
        // console.log("disconnected", data)
        ref.linkStatusTarget.classList.remove("text-muted")
        ref.linkStatusTarget.classList.remove("text-warning")
        ref.linkStatusTarget.classList.remove("text-success")
        ref.linkStatusTarget.classList.add(data.willAttemptReconnect ? "text-warning" : "text-danger")
        ref.linkStatusTarget.title = "live updates disabled"
      },

      received(data) {
        if(data?.action == "refresh") Turbo.visit(window.location.href)
      }
    })
  }

  async checkForUpdates() {
    const request = new FetchRequest("get", this.urlValue)
    const response = await request.perform()
    if(!response.ok) return false

    const json = await response.json
    if(json?.uuid && !json?.enqueued_at) Turbo.visit(window.location.href)
  }
}


// async toggleRemote(sopt, sval) {
//   const request = new FetchRequest("post", this.urlValue, {
//     body: JSON.stringify({ sopt, sval })
//   })
//   const response = await request.perform()
//   if (response.ok) {
//     return await response.json
//   } else {
//     throw `${response.statusCode}: ${response.response.statusText}`
//   }
// }
