import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bs--toast"
export default class extends Controller {
  static targets = ["message"]

  connect() {}

  messageTargetConnected(el) {
    if(!el.parentElement.classList.contains("toast-container")) {
      this.ensureContainer().append(el)
    }

    el.addEventListener("show.bs.toast", ev => {
      this.containerVisibility(el.parentElement)
    })

    // el.addEventListener("hidden.bs.toast", ev => {
    //   const parent = el.parentElement
    //   el.remove()
    //   this.containerVisibility(parent)
    // })

    bootstrap.Toast.getOrCreateInstance(el).show()
  }

  get container() { return document.getElementById("mainToastCtn") }

  containerVisibility(ctn) {
    ctn.classList.toggle("d-none", ctn.children.length == 0)
  }

  ensureContainer() {
    const ctn = this.container
    if(ctn) return ctn
    const div = document.createElement("div")
    div.id = "mainToastCtn"
    div.classList.add("toast-container", "position-fixed", "top-0", "end-0", "p-3", "d-none")
    document.body.prepend(div)
    return div
  }
}
