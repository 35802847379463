import { Controller } from "@hotwired/stimulus"
import Split from "split.js"

// Connects to data-controller="rich-template-preview"
export default class extends Controller {
  static targets = ["input", "assigns", "frame", "rendererSelect"]

  connect() {
    this.rendererSelectTarget.addEventListener("change", ev => this.debouncedUpdate())
    this.assignsTarget.addEventListener("change", ev => this.debouncedUpdate())
    this.inputTarget.addEventListener("change", ev => this.debouncedUpdate())
    this.inputTarget.dispatchEvent(new Event("change", { "bubbles": true }))

    Split([`[data-template-editor]`, `[data-template-frame]`], {
      direction: 'vertical',
      sizes: [50, 50], // Initial sizes in percentages
      gutterSize: 12,   // Size of the draggable divider
    })
  }

  debouncedUpdate() {
    this.frameTarget.classList.add("opacity-50")
    clearTimeout(this.timer)
    this.timer = setTimeout(_ => this.updatePreview(), 1000)
  }

  updatePreview() {
    const iframeDoc = this.frameTarget.contentDocument || this.frameTarget.contentWindow?.document
    this.scrollPosWas = iframeDoc?.documentElement?.scrollTop

    this.element.target = this.element.dataset.previewTarget
    const actionWas = this.element.action
    this.element.action = this.element.dataset.previewUrl
    this.element.submit()

    this.frameTarget.classList.remove("opacity-50")

    this.element.action = actionWas
    this.element.target = "_self"

    this.frameTarget.addEventListener("load", _ => {
      // fetch document again because it got replaced on load
      const iframeDoc = this.frameTarget.contentDocument || this.frameTarget.contentWindow?.document
      if(iframeDoc.documentElement) iframeDoc.documentElement.scrollTop = this.scrollPosWas
      if(iframeDoc.body) iframeDoc.body.scrollTop = this.scrollPosWas
    }, { once: true })
  }
}
