import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import Sortable from "sortablejs"

// Connects to data-controller="sortable"
export default class extends Controller {
  connect() {
    const sortableOpts = {}
    for (const [key, value] of Object.entries(this.element.dataset)) {
      if(!key.startsWith("sortable")) continue;
      sortableOpts[key.charAt(8).toLowerCase() + key.slice(9)] = value
    }

    if (sortableOpts.remote) {
      sortableOpts.onEnd = async (event) => {
        if(event.oldIndex == event.newIndex) return false
        const items = Array.from(this.element.children)
        const movedLinkId = items[event.newIndex].dataset.id
        const prevItem = items[event.newIndex - 1]
        const nextItem = items[event.newIndex + 1]

        this.updateRemote(sortableOpts.remote, {
          moved_link_id: movedLinkId,
          prev_id: prevItem ? prevItem.dataset.id : null,
          next_id: nextItem ? nextItem.dataset.id : null,
        }).then(_ => {
          [prevItem, nextItem].forEach(el => {
            el?.classList.remove("highlight-flash-warning", "bg-opacity-10")
            el?.offsetWidth
            el?.classList.add("highlight-flash-warning", "bg-opacity-10")
            el?.addEventListener("animationend", _ => {
              el.classList.remove("highlight-flash-warning")
            }, { once: true })
          })
          event.item.classList.remove("highlight-flash-success")
          event.item.offsetWidth
          event.item.classList.add("highlight-flash-success")
          event.item.addEventListener("animationend", _ => {
            event.item.classList.remove("highlight-flash-success")
          }, { once: true })
        }).catch(err => {
          event.item.classList.add("bg-danger-subtle")
          console.error(err)
          $(`
            <div class="toast align-items-center fade text-bg-danger mb-3" data-bs--toast-target="message" data-bs-autohide="false">
              <div class="d-flex align-items-center">
                <div class="toast-body">Failed to synchronize sorting action with remote: ${err}</div>
                <button type="button" class="btn ms-auto inherit-color" data-bs-dismiss="toast" aria-label="Close"><i class="bi bi-x-lg"></i></button>
              </div>
            </div>
          `).appendTo(document.body)
        })
      }
    }

    this.sortable = Sortable.create(this.element, sortableOpts)
  }

  async updateRemote(remote, payload) {
    const request = new FetchRequest("post", remote, {
      body: JSON.stringify(payload)
    })
    const response = await request.perform()
    if (response.ok) {
      return await response.json
    } else {
      throw `${response.statusCode}: ${response.response.statusText}`
    }
  }
}
